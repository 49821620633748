export const sectionList = [
  'headerWithParagraph', //contains header,subheader,paragraph,list,links,image.
  'headerWithParagraphButton', //contains header,subheader,paragraph,list,Buttons,image.
  'headerWithParagraphImageRight', //contains header,subheader,paragraph,list,links and image on right.
  'contentListColumn', //contains column structure for equal distribution of width in one row.
  'columnListWith2Items', //contains column structure for 2 columns in one row.
  'headerWithParagraphImageLeft', //contains header,subheader,paragraph,list,links and image on Left.
  'headerWithImage', // contains header,subheader,paragraph,list,links,image with width 80%.
  'contentListWithRightData', // contains list of contents where there is one with float right.
  'cardRight', // right card
  'marketoForm', //contains marketo form.
  'marketoFormClear', //contains marketo form where label and field are not inline.
  'faqs', // contains FAQs section
  'headerWithButtonImageRight', //contains header,subheader,paragraph,list,buttons and image on right.
  'imageWithPath', //contains image with path
  'contentWithListOfImages', //contains structure for list of imagesWithPath Data.
  'contentWithLeftData', //contains left data with specific width
  'headerWithParagraphRight', //content will be at right side with specific width

  // slp component list
  'slpSectionList', // contains column structure for equal distribution of width in one row for slp pages
  'slpGreenFooter', // contains footer structure for slp pages which has green background
  'slpGreyFooter', // contains footer structure for slp pages which has grey background
  'slpMarketoForm', // contains marketo form for slp pages
  'slpStickyNote', // contains sticky note for slp pages which is right side of the screen
  'slpSvgWithBackgroundImage', // contains SVG image for slp pages
  'slpImageWithHeader', // contains slp pages heading images
  'slpTopHeaderWithPath', // contains SLP pages header which has path
  'slpBackgroundImageWithTitle', // contains SLP pages which has background and text on it
  'slpImageContainer', // contains SLP pages which has Image as a background and another image on the image
  'slpTitleContainer', // contains SLP pages which has Image as a background and title
  'slpCircleCard', // contains slp card circle
  'slpWhiteFooter', //contains footer of white background
  'slpOffersList', // contains slp pages offer list
  'slpMarketoFormCard', //contains slp marketo form card
  'slpCardSectionList', //contains for cards on slp pages
  'slpRichTextWithImageRight', // contains slp pages component which has image on right side
  'slpMarketoFooter', //contains slp marketo form footer text
  'slpImageWithParagraph', // contains slp pages image and paragraph
  'slpCRPContentList',
  'slpCRPFooter',
  'slpCRPImageWithText',
  'slpCRPListHeaderWithParagraph',
  'slpCRPRichText',
  'slpTopTitle',
  'headerWithParagraphCard', //contains header with paragraph which has border to container
  'slpWelcomeMarketoForm', // contains marketo form for slp welcome page
  'slpOilHeader', //contains header of slp oil page
  'slpThankYouContent', //contains white content on slp thankyou page.
  'slpThankYouHeader', //contains header of slp thankyou page.
  'slpWelcomeThankyou', //contains Structure for slp welcome thankYou page.
  'slpHeaderWithText', //contains header having image and text.
  'slpWelcomeSectionList', //section list having animation effects.
 'iframeContainer',//contains header details with iframe for career
  'slpHeaderWithImageText', //contains header,image,and text.
  'slpOilOffers', //contains structure for offers section on free fill oil page.
  'slpOilImageContainer', //contains SLP pages which has Image as a background and another image on the image for slp propane and oil free fill pages.
  'headerWithParagraphCenter', //contains header,subheader,paragraph,list,links,image at center.
];

export const FormTypes = [
  'marketoForm',
  'marketoFormClear',
  'slpMarketoForm',
  'slpWelcomeMarketoForm',
  'slpMarketoFormCard',
];
