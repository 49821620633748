import { graphql } from 'gatsby';
import * as React from 'react';
import loadable from '@loadable/component';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import SlpGlobalStyles from '../../styles/slpGlobalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import LogoOG from '../../images/LogoOG.png';

const StandalonePageTemplate = ({ data }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  const sectionsArray =
    data?.allContentfulIslandPetroleum?.edges[0]?.node?.sections;
  return !loaded ? (
    <>
      <SlpGlobalStyles />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (
              section?.type &&
              sectionList.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
    </>
  ) : (
    <>
      <SlpGlobalStyles />
      <>
        {sectionsArray &&
          sectionsArray.map((section, index) => {
            if (section?.type && sectionList.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(`../../components/${section.type}/${section.type}`)
              );
              return <SectionComponent key={index} sectionData={section} />;
            }
            return <></>;
          })}
      </>
    </>
  );
};

export default StandalonePageTemplate;

export const pageQuery = graphql`
  query allStandalonePagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulImageWithPath: Boolean!
    $contentfulImageContainer: Boolean! # $contentfulMultimediaText: Boolean!
    $contentfulListImagesWithPath: Boolean!
  ) {
    allContentfulIslandPetroleum(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          title
          metadata
          hasIndex
          node_locale
          sections {
            __typename
            ...ImageWithPath @include(if: $contentfulImageWithPath)
            ...ImageContainer @include(if: $contentfulImageContainer)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...ContentfulListImages @include(if: $contentfulListImagesWithPath)
          }
        }
      }
    }
  }
`;
